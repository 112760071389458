.header-main-container {
  display: flex;
  background-color: #000;
  padding: 15px 80px;
  justify-content: space-between;
  align-items: center;
}

.nav-links,
a {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 30px;
  color: #fff;
}
.jtech-logo {
  width: 200px;
}
.jtech-logo img {
  width: 100%;
  height: 100%;
}
.MuiListItem-root:hover {
  background-color: #9929f2;
  border-radius: 5px;
}
.nav-links a:hover {
  color: #9929f2;
}

.future-btn button {
  display: flex;
  align-items: center;
}
.burger-icon svg:hover {
  border-radius: 20px;
}
.burger-icon button:hover {
  background-color: #9929f2 !important;
  border-radius: 5px;
}
.close-drawer-x {
  width: 100%;
  text-align: end;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}
.close-drawer-x span {
  cursor: pointer;
}
@media (max-width: 990px) {
  .header-main-container {
    padding: 10px 50px;
  }
}

@media (max-width: 568px) {
  .header-main-container {
    padding: 10px 20px;
  }
}
