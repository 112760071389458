.footer-main-container {
  background-image: url("../../../assets/icons/footer-bg.svg");
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 50px 0px;
}
.footer-heading-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 50px;
}
.footer-h2 {
  font-size: 38px;
  font-weight: 700;
}
.footer-heading-button button {
  padding: 16px 27px;
  text-transform: uppercase;
  background-color: #9929f2;
  font-size: 14px;
  font-weight: 700;
  font-family: "Montserrat";
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border: none;
}
.footer-heading-button button:hover {
  outline: 1px solid white;
}
.border-line {
  width: 100%;
  padding: 0px 100px;
}
hr {
  border: 1px solid #fff;
  opacity: 0.1;
  padding: 0px 50px;
}
.footer-bottom-container {
  width: 100%;
  padding: 0px 50px;
}
.footer-content {
  width: 90%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}
.footer-content-box {
  width: 95%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.footer-nav-links {
  margin-right: 75px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.footer-nav-links a {
  font-size: 18px;
  font-weight: 400;
}
.footer-nav-links a:hover {
  color: #9929f2;
}
.footer-left-box {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.footer-creative-agency {
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
}
.footer-lets-chat {
  font-size: 38px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.footer-lets-chat svg {
  width: 30px;
  height: 30px;
  display: flex;
}
.footer-lets-chat svg:hover {
  background-color: #9929f2;
  border-radius: 5px;
}

.footer-contact-email a {
  font-weight: 700;
  font-size: 18px;
  display: inline;
}
hr {
  width: 100%;
}
.footer-socials-and-rights {
  display: flex;
  width: 90%;
}
.footer-socials-links {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer-socials-links button {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.footer-socials-links button:hover {
  background-color: #9929f2;
}
.footer-jtech-rights {
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 899px) {
  .footer-nav-links {
    margin-right: 40px;
  }
  .border-line {
    padding: 0px 30px;
  }
  .footer-bottom-container {
    padding: 0px 0px;
  }
}
@media (max-width: 768px) {
  .footer-nav-links {
    margin-right: 0px;
  }

  .footer-contact-email {
    font-size: 16px;
  }
  .footer-creative-agency {
    font-size: 16px;
  }
  .footer-lets-chat {
    font-size: 28px;
    justify-content: center;
  }
  .footer-nav-links {
    font-size: 16px;
  }
}
@media (max-width: 678px) {
  .footer-nav-links {
    align-items: center;
  }
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }
  .footer-content-box {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }
  .footer-left-box {
    gap: 20px;
  }
}
@media (max-width: 568px) {
  .footer-main-container {
    padding: 50px 0px;
  }
  .border-line {
    padding: 0px 30px;
  }
  .footer-h2 {
    font-size: 24px;
  }
  .footer-nav-links a {
    margin-right: 0px;
  }
  .footer-jtech-rights {
    font-size: 14px;
  }
}
