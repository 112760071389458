/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap"); */

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
@font-face {
  font-family: "Montserrat";
  src: url("./font-family/Montserrat-VariableFont_wght.ttf");
}
.mobile-drawer {
  border: none;
  gap: 25px;
  box-shadow: none;
  flex-direction: column !important;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: black;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(#9322f0, #ebaa38);
  border-radius: 50px;
}

.MuiPaper-root {
  box-shadow: none !important;
  height: 100vh;
  width: auto;
  width: 100%;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: black !important;
  color: #fff !important;
}
.MuiPaper-root ul {
  width: 100% !important;
}

.MuiPaper-root ul span {
  font-family: "Montserrat" !important;
}
.MuiButtonBase-root:hover {
  background-color: white;
}
